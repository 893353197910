<template>
	<!-- 耕雲導師讲词 -->
	<div class="word">
		<div v-for="(item,index) of contentList" :key="index" style="max-width:100%;">
			<table border='1' cellspacing="0">
				<tr class="ta-l">
					<th colspan="4">{{item.cate_name}}</th>
				</tr>
				<tr v-show="item.lists && item.lists[0].news_title" v-for="(item1,index1) in getLength(item.lists)"
					:key="index1" >
					<td style="width:30px;text-align:center;" >{{index1+1}}</td>
					<td style="width:200px;text-align:left;"  @click="linkToDetail(item.lists[index1])">{{item.lists[index1].news_title}}</td>
					<td style="width:30px;text-align:center;"  v-show="index1+getLength(item.lists) +1">
						<div v-if="item.lists[index1+getLength(item.lists)]">{{index1+getLength(item.lists) +1}}</div>
					</td>
					<td style="width:200px;text-align:left;">
						<div @click="linkToDetail(item.lists[index1+getLength(item.lists)])" class="" 
							v-if="item.lists[index1+getLength(item.lists)]">{{item.lists[index1+getLength(item.lists)].news_title}}</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
	import {
		getCateList,
		getSpeechList
	} from "../../api/apis";
	export default {
		name: "",
		components: {},

		data() {
			return {
				contentList: [ 
				],
				length: 0
			};
		},

		methods: {
			// 获取子列表lists的长度,向上取整
			getLength(arr) {
				let length = arr.length;
				this.length = Math.ceil(length / 2);
				return Math.ceil(length / 2);
			},
			//文章详情
			linkToDetail(item) {
				window.open(this.$BaseURL + '/articleDetail?name=word&id=' + item.id);
				// this.$router.push({
				//     path: "/articleDetail",
				//     query: { name: "word", id: item.id }
				// });
			},
			//初始化数据
			initData() {
				let list = this.contentList;
				this.length = Math.ceil(list.length / 2);

				//处理基数条数据的时候
				if (list.length % 2 === 1) {
					list[list.length] = {
						lists: []
					};
					list[list.length].lists.length =
						list[list.length - 1].lists.length;
				}
				//处理基偶条数据时，比较数据，采用最长的数据条数
				for (let i = 0; i < this.length; i++) {
					if (list[2 * i].lists.length > list[2 * i + 1].lists.length) {
						list[2 * i + 1].lists.length = list[2 * i].lists.length;
					} else {
						list[2 * i].lists.length = list[2 * i + 1].lists.length;
					}
				}
			}
		},
		created() {
			// this.initData();
			getCateList().then(res => {
				let id = res[1][0].id;
				getSpeechList(id).then(res => {
					this.contentList = res;
					this.initData();
				});
			});
		}
	};
</script>

<style lang="stylus" scoped>
	.ta-l {
		text-align: left;
		cursor: pointer;
	}

	.pl5 {
		// padding-left: 5px;
	}

	.word {
		// width: 750px;
		// margin: 20px 10px;
		margin: 20px 0;

		.title {
			font-size: 15px;
			font-weight: 600;
		}
	}

	table,
	td,
	tr,
	th {
		border: 1px solid #ACACAC;
		border-bottom: 0px solid #ACACAC;
	}

	th {
		width: 50%;
	}

	.line {
		width: 100%;
		height: 1px;
		background-color: #ACACAC;
	}

	table {
		width: 100%;
		font-size: 15px;

		th {
			line-height: 36px;
			font-weight: 600;
			padding-left: 15px;
		}

		td {
			line-height: 30px;
			// cursor: pointer;
		}

		td:hover {
			color: #1482d9;
		}

		.numTitle {
			// width: 60px;
		}

		.contentTitle {
			/* width: 378px; */
			// padding-left: 5px;
			text-align: left;
			white-space:nowrap;
			div {
				cursor: pointer;
			}
		}

		.contentTitle {
			/* width: 378px; */
			width: 200px;
			// padding-left: 5px;
			text-align: left;

			div {
				cursor: pointer;
			}
		}

		.leftTitle {
			// width: 350px;
		}
	}
</style>